'use client';

import { GoogleTagManager } from '@next/third-parties/google';
import Script from 'next/script';
import { usePathname } from 'next/navigation';

type RegisterGoogleTagManagerProps = {};

export const RegisterGoogleTagManager = ({}: RegisterGoogleTagManagerProps) => {
  const pathname = usePathname();
  const tagManagerId = process.env.GOOGLE_TAG_MANAGER_ID || 'GTM-T9V9HP4Q';
  const server =
    process.env.GOOGLE_TAG_MANAGER_DOMAIN || 'www.googletagmanager.com';

  if (pathname.startsWith('/blog')) {
    return null;
  }

  if (server == null || server == '' || server == 'www.googletagmanager.com') {
    return <GoogleTagManager gtmId={tagManagerId} />;
  }

  return (
    <>
      <Script
        id="gtm-script"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://${server}/gtm.js?id=${tagManagerId}';f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${tagManagerId}');
          `,
        }}
      />
    </>
  );
};
